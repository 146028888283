// Variables

//Fonts
@baseFont:  'Lato', sans-serif;
@baseFont2: 'Roboto', sans-serif;
@baseFont3: 'Lato', sans-serif;

//Colors
@baseColor: #42b6ff;
@baseColor2: #19506d;
@baseColor3: #267ae9;
@baseColor4: #888894;
@baseColor5: #443a44;
@baseColor6: #595f7c;
@baseColor7: #0c112a;
@baseColor8: #121a3e;
@baseColor9: #090d20;
@baseColor10: #d7dde0;
@baseColor11: #0fa3f2;
@baseColor12: #cde6f0;
@baseColor13: #fff;
@black: #080808;

// Mixins
.transition(@property: all, @duration: 300ms, @animate: ease-in-out, @delay:0s){
    transition: @arguments;
}