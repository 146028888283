.error_mark{
    text-align: center;
    float: right;
    width: 499px;
    height: 499px;
    padding-top: 150px;
    border-radius: 100%;
    box-shadow: 4px 0 79px fade(#2e2e2e,9%);
    margin: 122px auto 140px;
    img{
        max-width: 100%;
    }
    h4{
        font-weight: 300;
        color: @baseColor3;
        margin-top: 15px;
    }
}

.error_msg{
    padding-top: 220px;
    padding-left: 25px;
    h1{
        line-height: 60px;
        margin-bottom: 25px;
        font-size: 48px;
        font-weight: 100;
        font-family: @baseFont2;
        color: @baseColor3;
    }
    p{
        font-size: 18px;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .btn{
        letter-spacing: 1px;
        padding: 0 50px;
        line-height: 53px;
        i{
            margin-right: 15px;
        }
    }
}