.featureTab{
    .feature_tab_menu{
        background: #f9f9f9;
        border-bottom: 1px solid #d8d8d8;
        .nav{
            overflow: hidden;
            padding: 0 15px;
            border: none;
            margin-bottom: -1px;
            li{
                max-height: 103px;
                width: 25%;
                border-right: 1px solid #eaeaea;
                margin-bottom: 0;
                &:first-child{
                    border-left: 1px solid #eaeaea;
                }
                a{
                    border-radius: 0;
                    padding: 27px 49px;
                    height: 103px;
                    text-align: left;
                    display: block;
                    font-family: @baseFont2;
                    font-weight: 600;
                    color: @baseColor5;
                    line-height: 21px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    border: none;                    
                    border-bottom: 1px solid #d8d8d8;
                    cursor: pointer;
                    background: #f9f9f9;
                    margin: 0;
                    &:before{
                        content: '';
                        width: 100%;
                        height: 0;
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: @baseColor3;
                    }
                    .icon{
                        float: left;
                        margin-right: 30px;
                    }
                    &:hover,&:focus{
                        background: #f5f5f5;
                        &:before{
                            height: 10px;
                        }
                    }
                }
                &:nth-child(1),&:nth-child(2){
                    a{
                        line-height: 47px;
                    }
                }
                & + li{
                    a{
                    }
                }
                &.active{
                    a{
                        border-bottom-color: #fff;
                        border-top: none;
                        background: #fff;
                        &:before{
                            height: 5px;
                        }
                    }
                }
            }
        }
    } // Tab Menu
    .featureTab_contents{
        .tab-content{
            .tab-pane{
                .content_row{
                    padding: 115px 0;
                    .sectionTitle{
                        margin-bottom: 80px;
                    }
                    .img_part{
                        text-align: center;
                        img{
                            max-width: 100%;
                        }
                    }
                    p{
                        padding-top: 15px;
                        line-height: 28px;
                    }
                    .btn{
                        margin-top: 35px;
                    }
                    &.content_row2{
                        .sectionTitle{
                            margin-bottom: 45px;
                        }
                    }
                    &.content_row4{
                        .sectionTitle{
                            margin-bottom: 125px;
                        }
                    }
                    &.whats_under_hood{
                        background: #f6fafb;
                    }
                    .under_hood{
                        margin-top: 65px;
                        text-align: center;
                        padding: 0 70px;
                        .icon{
                            line-height: 40px;
                            margin-bottom: 15px;
                        }
                        p{
                            margin-top: 15px;
                            padding-top: 0;
                        }
                    }
                    .tech_feature{
                        .inner{
                            text-align: center;
                            padding: 59px 0 45px;
                            border: 1px solid #dae0e2;
                            h4{}
                            h3{
                                margin-top: 5px;
                                font-size: 23px;
                                font-weight: 300;
                            }
                            .icon{
                                line-height: 208px;
                                padding: 43px 0 57px;
                            }
                        }
                    }
                }
            }
        }
    } // Tab Content
}