.facts{
    padding: 0 0 80px;
    background: #f1f6f8;
    .fact{
        text-align: center;
        text-transform: uppercase;
        font-family: @baseFont2;
        font-weight: 300;
        font-size: 16px;
        strong{
            font-family: @baseFont2;
            font-weight: bold;
            font-size: 36px;
            color: #080808;
            display: block;
        }
        & + .fact{
            border-left: 1px solid #e4e4e4;
        }
    }
    &.facts2{
        padding-top: 80px;
        background: #fff;
    }
}