.facts{
    &.about_us_facts{
        background: #fff;
        padding: 80px 0;
    }
}

.about_us_content{
    padding-top: 50px;
    .col-sm-5{
        text-align: center;
        img{
            display: inline-block;            
        }
    }
    .col-sm-7{
        padding-top: 115px;
        .sectionTitle{
            margin-bottom: 60px;
        }
        h4{
            margin-bottom: 20px;
        }
        p{
        }
        hr{
            margin: 20px 0;
            background: #eaeaea;
        }
        ul{
            li{
                color: @baseColor3;
                margin-bottom: 20px;
                float: left;
                text-transform: uppercase;
                &:before{
                    width: 25px;
                }
                & + li{
                    margin-top: 0;
                    margin-left: 30px;
                }
            }
        }
    }
}