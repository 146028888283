.hosting_featue{
    background: #fff;
}

.like_us{
    padding: 60px 0;
}

.this_top_features{
    .this_top_feature{
        background: #fff;
        padding: 82px 0 57px;
        .media-left{
            width: 448px;
            padding-right: 100px;
        }
        .media-right{
            width: 448px;
            padding-left: 100px;
        }
        .media-body{
            h5{}
            h2{
                margin-top: 5px;
                font-weight: 300;
                font-size: 34px;
                margin-bottom: 30px;
            }
        }
        &:nth-child(even){
            background: #f1f6f8;
        }
        &:first-child{}
        &:nth-child(2){
            padding: 125px 0 80px;
        }
        &:nth-child(3){}
        &:last-child{
            padding: 110px 0 85px;
        }
    }
}