/*Prefix Styles*/
.m0{margin: 0;}
.p0{padding: 0;}

body{
    position: relative;
    // @todo what is this for
    .transition();
    /*Global Font*/
    *{
        position: relative;
        // @todo what is this for
    }
    /*Floating*/
    .fleft{ float: left; }
    .fright{ float: right; }

    /*Section Fix*/
    section, header, footer{
        &.row{
            margin: 0;
            position: relative;
        }
    }
}

/*Ancore*/
a,.btn,button{
    outline: none;
    color: @baseColor;
    .transition();
    &:before,&:after{
        .transition();
    }
    &:focus,&:hover{
        outline: none;
        text-decoration: none;
        .transition();
    }
}
