.sectionTitle{
    text-align: center;
    margin-bottom: 50px;
    h5{
        
    }
    h2{
        font-size: 48px;
        font-weight: 100;
    }
    &.text-left{
        text-align: left;
        margin: 0 0 35px;
        h3{
            font-size: 30px;
            font-weight: 100;
        }
    }
}