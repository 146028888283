.why_choose{
    padding: 80px 0 50px;
    background: #f6fafb;
    &.why_choose2{
        background: #f0f5f6;
    }
    &.home2{
        background: #fff;
    }
}

.cause2choose{
    margin-top: 30px;
    min-height: 150px;
    .media{
        .media-left{
            padding-right: 15px;
            a{
                img{
                    
                }
            }
        }
        .media-body{
            h4{
                margin-top: 15px;
                margin-bottom: 20px;
				font-size:15px;
            }
        }
    }
    &:nth-child(1),&:nth-child(2),&:nth-child(3){
        margin-top: 0;
    }
}