.latest_news{
    .post{
        margin: 0;
        & + .post{
            margin-top: 35px;
        }
        .media-left{
            padding-right: 30px;
            a{
                width: 112px;
                height: 74px;
                display: block;
                img{
                    max-width: 100%;
                }
            }
        }
        .media-body{
            h5{
                font-weight: 500;
                margin-bottom: 13px;
                a{
                    color: @baseColor5;
                    font-weight: 500;
                    &:hover{
                        color: @baseColor3;
                    }
                }
            }
            p{
                line-height: 20px;
                margin-bottom: 7px;
                font-weight: 300;
            }
            .timeAgo{
                color: #d7d7d7;
                font-size: 13px;
            }
        }
    }
}

.blog_content{
    padding: 68px 0;
}

.blog{
    .image{
        a{
            display: block;
            font-size: 36px;
            color: #fff;
            i{
                line-height: 300px;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
            &:hover{
                color: @baseColor;
                i{
                    
                }
            }
        }
        img{
            width: 100%;
        }
        .carousel-control{
            width: 7%;
            opacity: 1;
            .glyphicon{
                font-size: 18px;
            }
        }
    }
    .blog_infos{
        margin: 20px 0;
        display: block;
        li{
            float: left;
            padding: 0;
            & + li{
                &:before{
                    content: '';
                    width: 1px;
                    height: 14px;
                    float: left;
                    margin: 0 10px;
                    background: @baseColor3;
                }
            }
            a{
                color: @baseColor3;
                font-family: @baseFont2;
                font-weight: 300;                
                letter-spacing: .5px;
                line-height: 14px;
                text-transform: uppercase;
                padding: 0;
                white-space: nowrap;
                i{
                    margin-right: 8px;
                }
                &:hover{
                    background: none;
                    color: @baseColor5;
                }
            }
        }
    }
    h3{
        margin-bottom: 20px;
        a{
            color: @baseColor5;
            &:hover{
                color: @baseColor;
            }
        }
    }
    .btn{
        margin-top: 25px;
    }
    &.quote_blog{
        .media{
            padding: 50px 60px 50px 30px;
            background: #f1f6f8;
            .media-left{
                font-size: 40px;
                line-height: 40px;
                color: @baseColor3;
                padding-right: 30px;
            }
            .media-body{
                p{
                    letter-spacing: 1px;
                    color: @baseColor5;
                    font-weight: 500;
                    font-family: @baseFont2;
                    font-size: 17px;
                    line-height: 28px;
                }
                .quote_writer{
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: @baseColor5;
                    font-weight: bold;
                    font-family: @baseFont2;
                    margin-top: 20px;
                }
            }
        }
    }
    & + hr{
        margin: 40px 0;
        background: #eaeaea;
    }
}

.pagination_nav{
    padding-top: 30px;
    .pagination{
        border-radius: 0;
        display: table;
        margin: 0 auto;
        li{
            float: left;
            margin-bottom: 10px;
            & + li{
                margin-left: 10px;
            }
            a{
                padding: 0 19px;
                line-height: 36px;
                border-radius: 0;
                text-transform: uppercase;
                border: none;
                background: #f1f6f8;
                font-family: @baseFont3;
                font-weight: bold;
                font-size: 12px;
                letter-spacing: 1px;
                color: @baseColor5;
                &:hover{
                    background: @baseColor3;
                    color: #fff;
                }
            }
            &:first-child,
            &:last-child{
                a{
                    border-radius: 0;                    
                    font-family: @baseFont2;
                    font-size: 15px;
                }
            }
        }
    }
}

.single-blog{
    padding-bottom: 67px;
    .blog{
        .img_cap{
            margin: 50px 0;
            .media-left{
                padding-right: 30px;
                a{
                    display: block;
                    width: 263px;
                    height: 200px;
                    text-align: center;
                    img{
                        max-width: 100%;
                    }
                    .caption{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: @baseColor3;
                        color: #FFF;
                        font-family: @baseFont2;
                        font-size: 15px;
                        font-weight: 300;
                        text-transform: uppercase;
                        width: 100%;
                        line-height: 36px;
                    }
                }
            }
            .media-body{
                padding-top: 15px;
                h4{
                    font-size: 16px;
                    margin-bottom: 20px;
                }
                p{
                    & + p{
                        margin-top: 20px;
                    }
                }
            }
        }
        .quote_row{
            margin-top: 10px;
            hr{
                margin: 40px 0;
                background: #eaeaea;
            }
            blockquote{
                background: #f1f6f8;
                margin: 0;
                padding: 30px 50px 30px 40px;
                border-left-color: @baseColor3;
                p{
                    font-size: 16px;
                    font-family: @baseFont2;
                    font-weight: 300;
                    font-style: italic;
                    letter-spacing: 0;
                    line-height: 28px;
                }
            }
        }
    }
}