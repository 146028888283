.portfolio_content{
    padding: 65px 0;
}

.portfolio{
    padding: 15px;
    .image{        
        img{
            width: 100%;
        }
        a{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            line-height: 1;
            text-align: center;
            background: @baseColor3;
            color: #fff;
            display: block;
            overflow: hidden;
            .transition();
            i{
                position: absolute;
                top: ~"calc(50% - 7px)";
                left: ~"calc(50% - 7px)";
            }
        }
    }
    h5{
        margin: 15px 0;
        a{
            text-transform: uppercase;
            color: @baseColor5;
            line-height: 24px;
        }
    }
    p{
        margin-bottom: 15px;
    }
    &:hover{
        .image{
            img{}
            a{
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        h5{
            a{
                color: @baseColor;
            }
        }
    }
}

.col3{
    
}

.col6{
    margin-top: 120px;
}