.clients{
    padding: 40px 0 80px;
    background: #f1f6f8;
    .nav{
        li{
            a{
                text-align: center;
                margin: 0;
                &:hover,&:focus{
                    background: none;
                }
            }
        }
    }
}
.about_clients{ padding: 80px 0;}