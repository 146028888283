footer{
    background: @baseColor7;
    .top_footer{
        .quick_contact{
            background: @baseColor3;
            padding: 17px 0;
            margin-bottom: 76px;
            .nav{
                li{ width:32%;
                    text-align:center;
                    & + li{
                        border-left: 1px solid #3f8df3;
                        margin: 0;

                    }
                    a{
                        line-height: 56px;

                        font-size: 18px;
                        font-family: @baseFont;
                        font-weight: bold;
                        color: #fff;
                        text-transform: uppercase;
                        i{
                            font-size: 30px;
                            margin-right: 20px;
                            line-height: 50px;
                            display:inline-block;
                            vertical-align:sub;
                        }
                        &:hover{
                            background: @baseColor3;

                            color: @baseColor7;
                        }
                    }
                }
            }
        }
        .shortKnowledge{
            margin-bottom: 30px;
            h4{
                line-height: 33px;
                margin: 0 0 22px;
                text-transform: uppercase;
                color: #FFF;
                font-weight: 500;
                font-family: @baseFont2;
            }
            p{
                color: @baseColor6;
                line-height: 28px;
            }
            .about{
            }
            .product{
                h4{
                    margin-bottom: 27px;
                }
                .product_list{
                    li{
                        margin-top: 25px;
                        width: ~"calc(100% / 2)";
                        float: left;
                        &:nth-child(1),&:nth-child(2){
                            margin-top: 0;
                        }
                        a{
                            line-height: 20px;
                            font-family: @baseFont2;
                            float: left;
                            color: @baseColor6;
                            padding: 0;
                            &:before{
                                content: '';
                                float: left;
                                width: 14px;
                                height: 18px;
                                margin-right: 14px;
                                background: url(../../images/icons/footer/list-marker.png) no-repeat scroll center center;
                            }
                            &:hover{
                                color: #FFF;
                                background: none;
                            }
                        }
                    }
                }
            }            
        }
        .beInContact{
            border-top: 1px solid  #1a2140;
            margin-bottom: 30px;
            .country_select{
                float: left;
                width: 216px;
                border-right: 1px solid  #1a2140;
                padding-top: 35px;
                padding-bottom: 35px;
                button{
                    background: none;
                    border-radius: 0;
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    font-size: 18px;
                    font-family: @baseFont2;
                    font-weight: 500;
                    color: #fff;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    .flag{
                        width: 42px;
                        line-height: 43px;
                        height: 43px;
                        margin-right: 20px;
                        display: inline-block;
                    }                    
                    i{
                        margin-left: 20px;
                        font-size: 18px;
                    }
                    &:hover,&:focus{
                        background: none;
                        color: #fff;
                    }
                }
                .dropdown-menu{
                    border-radius: 0;
                    margin-top: -20px;
                    background: #0fa3f2;
                    border: none;
                    li{
                        a{
                            text-transform: uppercase;
                            color: #fff;
                            font-family: @baseFont2;
                            font-size: 14px;
                            padding: 0 15px;
                            line-height: 24px;
                            &:hover{
                                background: @baseColor7;
                            }
                        }
                    }
                }
            }
            .social_icos{
                padding: 38px 0 28px;
                text-align: center;
                float: left;
                width: 293px;
                border-right: 1px solid  #1a2140;
                ul{
                    display: inline-block;
                    li{
                        padding: 0 6px;
                        float: left;
                        a{
                            padding: 0;
                            width: 42px;
                            line-height: 42px;
                            height: 42px;
                            color: #FFF;
                            background: #192043;
                            text-align: center;
                            border-radius: 100%;
                            &:hover{
                                color: @baseColor6;
                            }
                        }
                    }
                }
            }
            .subscribe_form{
                padding-left: 39px;
                padding-top: 33px;
                float: left;
                .form-inline{
                    .form-group{
                        label{
                            text-transform: uppercase;
                            margin-bottom: 0;
                            color: #fff;
                            font-family: @baseFont2;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 21px;
                            letter-spacing: 1px;
                            float: left;
                            small{
                                display: block;
                                text-transform: capitalize;
                                font-weight: 300;
                                font-size: 14px;
                                color: @baseColor6;
                                line-height: 1;
                            }
                        }
                        .input-group{
                            margin-left: 45px;
                            float: left;
                            width: 440px;
                            padding: 0;
                            .form-control{                                
                                background: @baseColor8;
                                height: 45px;
                                line-height: 45px;
                                padding: 0 20px;
                                border-radius: 0;
                                border: none;
                                color: #454d75;
                                font-size: 13px;
                                font-family: @baseFont2;
                                font-weight: 300;
                            }
                            .input-group-addon{
                                border: none;
                                padding: 0;
                                border-radius: 0;
                                background: none;
                                width: 65px;                                
                                input{
                                    text-transform: uppercase;
                                    font-size: 18px;
                                    font-weight: bold;
                                    font-family: @baseFont2;
                                    color: #fff;
                                    padding: 0 20px;
                                    line-height: 45px;
                                    border: none;
                                    border-radius: 0;
                                    background: @baseColor8;
                                    float: left;
                                    width: 65px;
                                    &:hover{
                                        color: @baseColor3;
                                    }
                                }
                            }
                        }
                    }                
                }
            }
        }
    }
    .copyright_line{
        text-align: center;
        line-height: 25px;
        padding: 25px 0;
        background: @baseColor9;
        color: @baseColor6;
        font-family: @baseFont2;
        font-weight: 300;
        letter-spacing: 1px;
        font-size: 13px;
    }
}
