.faqs_content{
    padding: 70px 0;
    h4.menuTitle{
        margin-bottom: 25px;
    }
    .faq_category{
        width: 278px;
        padding-right: 0;
        .nav{
            border: none;
            overflow: hidden;
            li{
                display: block;
                float: none;
                a{
                    border-radius: 0;
                    padding: 12px;
                    text-align: left;
                    display: block;
                    font-family: @baseFont2;
                    font-weight: 600;
                    color: @baseColor5;
                    line-height: 21px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    border: none;
                    cursor: pointer;
                    background: #f9f9f9;
                    margin: 0;
                    .icon{
                        margin-right: 15px;
                    }
                    &:hover,&:focus{
                        background: #f5f5f5;
                    }
                }
                & + li{
                    margin-top: 17px;
                    a{
                    }
                }
                &.active{
                    a{
                        border-bottom-color: #fff;
                        color: @baseColor13;
						border-top: none;
                        background: @baseColor;
                    }
                }
            }
        }
    }
    .faq_content{
        width: ~"calc(100% - 278px)";
        padding-left: 0;
        .faq{
            padding: 35px 0;
            &:first-child{
                padding-top: 0;
            }
            & +.faq{
                margin-top: 0;
                border-top: 1px solid #eaeaea;
            }
            .media-left{
                padding: 0 30px;
                width: 127px;
                text-align: center;
                font-size: 24px;
                font-family: @baseFont2;
                font-weight: bold;
                color: @baseColor3;
                line-height: 1;
            }
            .media-body{
                p{
                    margin-top: 15px;
                }
            }
        }
    }
}